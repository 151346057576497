import { isAxiosError } from 'axios';
import { PROGRAM_API, PROGRAMS_API } from './programs';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';
import ReactDOMServer from 'react-dom/server';

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === 'string') {
    return data;
    // switch (data) {
    //   case "name already in use":
    //     return "Nome ja está em uso.";

    //   case "Supervisor já tem 30 programas":
    //     return "Supervisor já tem 30 programas";

    //   default:
    //     return defaultMsg;
    // }
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class ProgramsService {
  create = async ({
    name,
    application_phase,
    status,
    procedure,
    criterion,
    materials,
    suggested_reinforcer,
    attemps_number,
    objectives,
    patient_uuid,
    supervisor_uuid,
  }) => {
    try {
      const response = await customAxios.post(PROGRAM_API, {
        name,
        application_phase,
        status,
        procedure,
        criterion,
        materials,
        suggested_reinforcer,
        attemps_number,
        objectives,
        patient_uuid,
        supervisor_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível criar programa';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 409:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  readObjectives = async (uuid) => {
    try {
      const response = await customAxios.get(`${PROGRAM_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível carregar programa');
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${PROGRAM_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível carregar programa');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${PROGRAMS_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar programas');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    {
      name,
      application_phase,
      status,
      procedure,
      criterion,
      materials,
      suggested_reinforcer,
      attemps_number,
      objectives,
      patient_uuid,
      supervisor_uuid,
    }
  ) => {
    try {
      const response = await customAxios.put(`${PROGRAM_API}/${uuid}`, {
        name,
        application_phase,
        status,
        procedure,
        criterion,
        materials,
        suggested_reinforcer,
        attemps_number,
        objectives,
        patient_uuid,
        supervisor_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível editar programa';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 409:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(`${PROGRAM_API}/${uuid}`, {
        status,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar programa';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${PROGRAM_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível apagar programa';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  getGraphData = async (uuid, filter) => {
    try {
      const response = await customAxios.get(
        `${PROGRAM_API}/graph/${uuid}${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar gráfico');
        }
      }

      throw new Error(error);
    }
  };
}

export default new ProgramsService();
