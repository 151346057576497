import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  Icon,
  IconButton,
} from '@mui/material';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';

import MDButton from 'shared/components/MDButton';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';

import {
  initialValuesPrograms,
  initialValuesObjectives,
} from 'shared/pages/patients/schemas/initial_values';
import { programsValidations } from 'shared/pages/patients/schemas/validations';
import FormField from 'shared/components/form_field';
import {
  handleValue,
  renderInput,
  noOptionsTextMinCharacters,
} from 'shared/components/autocomplete/autocompleteConfigs';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import ModalPatientTemplates from 'shared/components/modals/modalPatientTemplates';
import ProgramsService from 'shared/services/programs/programs.service';
import { userLogged, translateAplicationPhase } from 'utils/essentialData';
import applicationPhasesService from 'shared/services/application_phases/applicationPhases.service';
import patientsService from 'shared/services/patients/patients.service';
import { translateProgramStatus } from 'utils/essentialData';

const statusOptions = ['ongoing', 'finished'];

function ProgramEdit({ setProgramStep, programId }) {
  const { uuid } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [selectedTemplateItem, setSelectedTemplateItem] = useState();

  const [supervisorData, setSupervisorData] = useState();
  const [applicationPhasesData, setApplicationPhasesData] = useState();

  const loggedUser = userLogged() ?? undefined;
  const permitionsFields = () => [99].includes(loggedUser.role);

  const filterOptionsApplicationPhase = createFilterOptions({
    stringify: (option) => translateAplicationPhase(option),
  });

  const filterOptionsProgramStatus = createFilterOptions({
    stringify: (option) => translateProgramStatus(option),
  });

  const handleSubmit = async (values, actions) => {
    const { supervisor_uuid, application_phase } = values;
    try {
      const data = {
        ...values,
        patient_uuid: uuid,
        application_phase: application_phase.uuid,
        supervisor_uuid:
          loggedUser.role === 1 ? loggedUser.uuid : supervisor_uuid.uuid,
      };
      await ProgramsService.edit(programId, data);
      toastSuccess('Programa criado com sucesso');
      setProgramStep('list');
    } catch (e) {
      toastError('Não foi possivel criar arquivo');
    }
  };

  const loadProgram = async () => {
    try {
      const res = await ProgramsService.read(programId);

      setInitialValuesFromApi({
        name: res.name,
        application_phase: res.application_phase,
        status: res.status,
        procedure: res.procedure,
        criterion: res.criterion,
        materials: res.materials,
        suggested_reinforcer: res.suggested_reinforcer,
        attemps_number: res.attemps_number,
        objectives: res.objectives,
        patient_uuid: uuid,
        supervisor_uuid: res.supervisor ? res.supervisor : '',
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadSupervisors = async (page = 1, limit = 25, filter = '') => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await patientsService.listRelationships(
        `?page=${page}&limit=${limit}&role=11&uuid=${uuid}${filterValue}`
      );
      if (res) {
        setSupervisorData(res);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  //modal delete
  const handleDelete = () => setOpenDeletePopup(true);

  const handleCancelDelete = () => setOpenDeletePopup(false);

  const handleApplyDelete = async () => {
    try {
      await ProgramsService.delete(programId);
      toastSuccess('Programa apagado com sucesso');
      setProgramStep('list');
      // loadFilePatients(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenDeletePopup(false);
    }
  };

  const loadApplicationPhases = async (page = 1, limit = 25, filter = '') => {
    try {
      const res = await applicationPhasesService.list(`?category=true`);
      if (res) {
        setApplicationPhasesData(res);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    if (selectedTemplateItem) {
      const objectiveRemap = selectedTemplateItem.objectives.map((v) => ({
        name: v.name,
        uuid: v.uuid,
      }));

      setInitialValuesFromApi({
        name: selectedTemplateItem.name,
        application_phase: selectedTemplateItem.application_phase,
        status: selectedTemplateItem.status,
        procedure: selectedTemplateItem.procedure,
        criterion: selectedTemplateItem.criterion,
        materials: selectedTemplateItem.materials,
        suggested_reinforcer: selectedTemplateItem.suggested_reinforcer,
        attemps_number: selectedTemplateItem.attemps_number,
        objectives: objectiveRemap ?? [],
        supervisor_uuid:
          loggedUser.role === 1
            ? loggedUser.uuid
            : selectedTemplateItem.supervisor,
      });
    }
  }, [selectedTemplateItem]);

  useEffect(() => {
    if (programId) {
      loadProgram();
      loadApplicationPhases();
    }
  }, [programId]);
  return (
    <>
      <Formik
        initialValues={initialValuesFromApi ?? initialValuesPrograms}
        validationSchema={programsValidations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            application_phase: application_phaseV,
            status: statusV,
            procedure: procedureV,
            criterion: criterionV,
            materials: materialsV,
            suggested_reinforcer: suggested_reinforcerV,
            attemps_number: attemps_numberV,
            objectives: objectivesV,
            supervisor_uuid: supervisor_uuidV,
          } = values;
          return (
            <Form id='user-edit-form' autoComplete='off'>
              <MDBox id='basic-info' sx={{ overflow: 'visible', pt: 3 }}>
                <MDBox display='flex' justifyContent='space-between'>
                  <MDTypography variant='h5' pb={3}>
                    Editar programa
                  </MDTypography>

                  <MDButton
                    variant='outlined'
                    color='primary'
                    sx={{ height: 'fit-content' }}
                    onClick={() => setOpenModalTemplate(true)}
                  >
                    Templates
                  </MDButton>
                </MDBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      name='name'
                      label='Nome *'
                      type='text'
                      value={nameV}
                      error={errors.name && touched.name}
                      success={nameV.length > 0 && !errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={applicationPhasesData ?? []}
                      getOptionLabel={(opt) =>
                        translateAplicationPhase(opt.category)
                      }
                      value={handleValue(application_phaseV)}
                      isOptionEqualToValue={(option, value) =>
                        option.category === value.category
                      }
                      onChange={(e, value) =>
                        setFieldValue('application_phase', value)
                      }
                      filterOptions={filterOptionsApplicationPhase}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateAplicationPhase(option.category)}
                        </Box>
                      )}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'application_phase',
                          'Fase de aplicação *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={statusOptions ?? []}
                      getOptionLabel={(opt) => translateProgramStatus(opt)}
                      value={handleValue(statusV)}
                      onChange={(e, value) => {
                        setFieldValue('status', value);
                      }}
                      filterOptions={filterOptionsProgramStatus}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateProgramStatus(option)}
                        </Box>
                      )}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'status',
                          'Status *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                </Grid>

                {permitionsFields() && (
                  <MDBox>
                    <Autocomplete
                      options={supervisorData?.data ?? []}
                      noOptionsText={noOptionsTextMinCharacters(supervisorData)}
                      getOptionLabel={(op) => op.name}
                      value={handleValue(supervisor_uuidV)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      onChange={(e, value) => {
                        setFieldValue('supervisor_uuid', value);
                      }}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'supervisor_uuid',
                          'Supervisor *',
                          handleBlur,
                          (event) => {
                            const { value } = event.target;
                            if (value && value.length >= 3) {
                              loadSupervisors(1, 50, value);
                            } else {
                              setSupervisorData();
                            }
                          }
                        )
                      }
                    />
                  </MDBox>
                )}

                <MDBox>
                  <FormField
                    name='procedure'
                    label='Procedimento *'
                    type='text'
                    value={procedureV}
                    error={errors.procedure && touched.procedure}
                    success={procedureV.length > 0 && !errors.procedure}
                    multiline
                    rows={4}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    name='criterion'
                    label='Critério *'
                    type='text'
                    value={criterionV}
                    error={errors.criterion && touched.criterion}
                    success={criterionV.length > 0 && !errors.criterion}
                    multiline
                    rows={4}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    name='materials'
                    label='Materiais'
                    type='text'
                    value={materialsV}
                    error={errors.materials && touched.materials}
                    success={materialsV.length > 0 && !errors.materials}
                    multiline
                    rows={4}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    name='suggested_reinforcer'
                    label='Reforçador sugerido'
                    type='text'
                    value={suggested_reinforcerV}
                    error={
                      errors.suggested_reinforcer &&
                      touched.suggested_reinforcer
                    }
                    success={
                      suggested_reinforcerV.length > 0 &&
                      !errors.suggested_reinforcer
                    }
                    multiline
                    rows={4}
                  />
                </MDBox>

                <MDBox>
                  <MDTypography variant='h6' pb={1}>
                    Objetivos
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <FormField
                    name='attemps_number'
                    label='Quantidade de tentativas *'
                    type='number'
                    value={attemps_numberV}
                    error={errors.attemps_number && touched.attemps_number}
                    success={
                      attemps_numberV.length > 0 && !errors.attemps_number
                    }
                  />
                </MDBox>

                {values.objectives && (
                  <MDBox mt={2}>
                    <FieldArray
                      name='objectives'
                      render={(arrayHelpers) => (
                        <>
                          {values.objectives.map((item, index) => (
                            <Grid
                              container
                              spacing={3}
                              key={index}
                              alignItems='center'
                            >
                              <Grid item xs={12} sm={0.5}>
                                <MDTypography variant='body2' size='small'>
                                  {index + 1}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={12} sm={10}>
                                <FormField
                                  name={`objectives[${index}].name`}
                                  label='Nome do objetivo'
                                  type='text'
                                  value={objectivesV[index].name}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        sx={{
                                          visibility: objectivesV[index].name
                                            ? 'visible'
                                            : 'hidden',
                                        }}
                                        size='small'
                                        onClick={() =>
                                          setFieldValue(
                                            `objectives[${index}].name`,
                                            ''
                                          )
                                        }
                                      >
                                        <Icon>clear</Icon>
                                      </IconButton>
                                    ),
                                  }}
                                />
                              </Grid>

                              {values.objectives.length > 1 && (
                                <Grid item xs={12} sm={1}>
                                  <IconButton
                                    title='Deletar'
                                    color='error'
                                    onClick={() => {
                                      let objectivesFilter = objectivesV;
                                      objectivesFilter.splice(index, 1);
                                      setFieldValue(
                                        'objectives',
                                        objectivesFilter
                                      );
                                    }}
                                  >
                                    <Icon>delete</Icon>
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ))}

                          {typeof errors.objectives === 'string' && (
                            <MDBox>
                              <MDTypography
                                component='div'
                                variant='caption'
                                color='error'
                                align='center'
                                fontWeight='regular'
                                mt={0.75}
                              >
                                <ErrorMessage name='objectives' />
                              </MDTypography>
                            </MDBox>
                          )}
                          <MDBox mb={2}>
                            <MDButton
                              onClick={() =>
                                arrayHelpers.push(initialValuesObjectives)
                              }
                              variant='outlined'
                              color='info'
                              mb={2}
                            >
                              <Icon>add</Icon>
                              Adicionar Objetivo
                            </MDButton>
                          </MDBox>
                        </>
                      )}
                    />
                  </MDBox>
                )}

                <MDBox display='flex' alignItems='space-between' gap={3}>
                  <MDButton
                    color='error'
                    type='button'
                    size='large'
                    onClick={handleDelete}
                    variant='outlined'
                  >
                    excluir
                  </MDButton>

                  <MDBox display='flex' width='24rem' ml='auto' gap={3}>
                    <MDButton
                      color='secondary'
                      type='button'
                      size='large'
                      onClick={() => setProgramStep('list')}
                      fullWidth
                      variant='outlined'
                    >
                      cancelar
                    </MDButton>
                    <MDButton
                      disabled={isSubmitting}
                      color='primary'
                      type='submit'
                      size='large'
                      fullWidth
                    >
                      SALVAR
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Form>
          );
        }}
      </Formik>
      <PopUpDelete
        open={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title='Exclusão de programa'
        contentText='Tem certeza que deseja excluir este programa?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />

      <ModalPatientTemplates
        open={openModalTemplate}
        onClose={() => {
          setOpenModalTemplate(false);
        }}
        setTemplateValue={setSelectedTemplateItem}
        requestApi={ProgramsService}
      />
    </>
  );
}

ProgramEdit.defaultProps = {
  setProgramStep: null,
};

ProgramEdit.propTypes = {
  setProgramStep: PropTypes.func,
  programId: PropTypes.string.isRequired,
};

export default ProgramEdit;
