import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Form, Formik } from 'formik';
import { Autocomplete, Box, createFilterOptions, Grid } from '@mui/material';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';

import MDButton from 'shared/components/MDButton';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';

import FormField from 'shared/components/form_field';
import {
  handleValue,
  renderInput,
  noOptionsTextMinCharacters,
} from 'shared/components/autocomplete/autocompleteConfigs';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import { initialValuesBehaviors } from 'shared/pages/patients/schemas/initial_values';
import { behaviorsValidationsEdit } from 'shared/pages/patients/schemas/validations';
import FormFieldRadio from 'shared/components/form_field/form_field_radio';
import ModalPatientTemplates from 'shared/components/modals/modalPatientTemplates';
import behaviorsService from 'shared/services/behaviors/behaviors.service';
import {
  translateStatus,
  statusOptions,
  translateAplicationPhase,
  userLogged,
} from 'utils/essentialData';
import { useParams } from 'react-router-dom';
import applicationPhasesService from 'shared/services/application_phases/applicationPhases.service';
import patientsService from 'shared/services/patients/patients.service';

const radioOptionsType = [
  {
    value: 'duration',
    label: 'Duração',
  },
  {
    value: 'frequency',
    label: 'Frequência',
  },
];

function BehaviorEdit({ setStep, itemId }) {
  const { uuid } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [selectedTemplateItem, setSelectedTemplateItem] = useState();

  const [supervisorData, setSupervisorData] = useState();
  const [applicationPhasesData, setApplicationPhasesData] = useState();

  const loggedUser = userLogged() ?? undefined;

  const permitionsFields = () => [99].includes(loggedUser.role);

  const filterOptionsStatus = createFilterOptions({
    stringify: (option) => translateStatus(option),
  });

  const filterOptionsApplicationPhase = createFilterOptions({
    stringify: (option) => translateAplicationPhase(option),
  });

  const loadSupervisors = async (page = 1, limit = 25, filter = '') => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await patientsService.listRelationships(
        `?page=${page}&limit=${limit}&role=1&uuid=${uuid}${filterValue}`
      );
      if (res) {
        setSupervisorData(res);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    const {
      name,
      application_phase,
      procedure,
      register_type,
      status,
      supervisor_uuid,
    } = values;
    try {
      const data = {
        name,
        application_phase: application_phase.uuid,
        procedure,
        register_type,
        patient_uuid: uuid,
        status,
        supervisor_uuid:
          loggedUser.role === 1 ? loggedUser.uuid : supervisor_uuid.uuid,
      };

      await behaviorsService.edit(itemId, data);
      toastSuccess('Comportamento editado com sucesso');
      actions.setTouched({});
      actions.setSubmitting(false);
      setStep('list');
    } catch (e) {
      toastError('Não foi possivel criar arquivo');
    }
  };

  const loadBehavior = async () => {
    try {
      // await
      const res = await behaviorsService.read(itemId);
      if (res)
        setInitialValuesFromApi({
          ...res,
          supervisor_uuid: res.supervisor ? res.supervisor : '',
        });
    } catch (error) {
      toastError(error.message);
    }
  };

  const loadApplicationPhases = async (page = 1, limit = 25, filter = '') => {
    try {
      const res = await applicationPhasesService.list(`?category=true`);
      if (res) {
        setApplicationPhasesData(res);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  //modal delete
  const handleDelete = () => setOpenDeletePopup(true);
  const handleCancelDelete = () => setOpenDeletePopup(false);
  const handleApplyDelete = async () => {
    try {
      await behaviorsService.delete(itemId);
      toastSuccess('Comportamento apagado com sucesso');
      setStep('list');
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenDeletePopup(false);
    }
  };

  useEffect(() => {
    if (itemId) {
      loadBehavior();
      loadApplicationPhases();
    }
  }, [itemId]);

  useEffect(() => {
    if (selectedTemplateItem) {
      setInitialValuesFromApi({
        name: selectedTemplateItem.name,
        application_phase: selectedTemplateItem.application_phase,
        status: selectedTemplateItem.status,
        procedure: selectedTemplateItem.procedure,
        register_type: selectedTemplateItem.register_type,
        supervisor_uuid:
          loggedUser.role === 1
            ? loggedUser.uuid
            : selectedTemplateItem.supervisor,
      });
    }
  }, [selectedTemplateItem]);

  return (
    <>
      <Formik
        initialValues={initialValuesFromApi ?? initialValuesBehaviors}
        validationSchema={behaviorsValidationsEdit[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            application_phase: application_phaseV,
            status: statusV,
            procedure: procedureV,
            register_type: register_typeV,
            supervisor_uuid: supervisor_uuidV,
          } = values;
          return (
            <Form id='user-edit-form' autoComplete='off'>
              <MDBox id='basic-info' sx={{ overflow: 'visible', pt: 3 }}>
                <MDBox display='flex' justifyContent='space-between'>
                  <MDTypography variant='h5' pb={3}>
                    Editar comportamento
                  </MDTypography>

                  <MDButton
                    variant='outlined'
                    color='primary'
                    sx={{ height: 'fit-content' }}
                    onClick={() => setOpenModalTemplate(true)}
                  >
                    Templates
                  </MDButton>
                </MDBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      name='name'
                      label='Nome *'
                      type='text'
                      value={nameV}
                      error={errors.name && touched.name}
                      success={nameV.length > 0 && !errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={applicationPhasesData ?? []}
                      getOptionLabel={(opt) =>
                        translateAplicationPhase(opt.category)
                      }
                      value={handleValue(application_phaseV)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      onChange={(e, value) =>
                        setFieldValue('application_phase', value)
                      }
                      filterOptions={filterOptionsApplicationPhase}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateAplicationPhase(option.category)}
                        </Box>
                      )}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'application_phase',
                          'Fase de aplicação *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={statusOptions}
                      getOptionLabel={translateStatus}
                      value={handleValue(statusV)}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(e, value) => setFieldValue('status', value)}
                      filterOptions={filterOptionsStatus}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateStatus(option)}
                        </Box>
                      )}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'status',
                          'Status *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                </Grid>

                {permitionsFields() && (
                  <MDBox>
                    <Autocomplete
                      options={supervisorData?.data ?? []}
                      noOptionsText={noOptionsTextMinCharacters(supervisorData)}
                      getOptionLabel={(op) => op.name}
                      value={handleValue(supervisor_uuidV)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      onChange={(e, value) => {
                        setFieldValue('supervisor_uuid', value);
                      }}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'supervisor_uuid',
                          'Supervisor *',
                          handleBlur,
                          (event) => {
                            const { value } = event.target;
                            if (value && value.length >= 3) {
                              loadSupervisors(1, 50, value);
                            } else {
                              setSupervisorData();
                            }
                          }
                        )
                      }
                    />
                  </MDBox>
                )}

                <MDBox>
                  <FormField
                    name='procedure'
                    label='Procedimento'
                    type='text'
                    value={procedureV}
                    error={errors.procedure && touched.procedure}
                    success={procedureV.length > 0 && !errors.procedure}
                    multiline
                    rows={4}
                  />
                </MDBox>

                <MDBox>
                  <MDTypography variant='h6' pb={1}>
                    Forma de registro
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <FormFieldRadio
                    name='register_type'
                    value={register_typeV}
                    options={radioOptionsType}
                    validationDirection='column'
                    disabled
                  />
                </MDBox>

                <MDBox display='flex' alignItems='space-between' gap={3}>
                  <MDButton
                    color='error'
                    type='button'
                    size='large'
                    onClick={handleDelete}
                    variant='outlined'
                  >
                    excluir
                  </MDButton>

                  <MDBox display='flex' width='24rem' ml='auto' gap={3}>
                    <MDButton
                      color='secondary'
                      type='button'
                      size='large'
                      onClick={() => setStep('list')}
                      fullWidth
                      variant='outlined'
                    >
                      cancelar
                    </MDButton>
                    <MDButton
                      disabled={isSubmitting}
                      color='primary'
                      type='submit'
                      size='large'
                      fullWidth
                    >
                      SALVAR
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Form>
          );
        }}
      </Formik>
      <PopUpDelete
        open={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title='Exclusão de comportamento'
        contentText='Tem certeza que deseja excluir este comportamento?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />

      <ModalPatientTemplates
        open={openModalTemplate}
        onClose={() => {
          setOpenModalTemplate(false);
        }}
        type='comportamento'
        setTemplateValue={setSelectedTemplateItem}
        requestApi={behaviorsService}
      />
    </>
  );
}

BehaviorEdit.defaultProps = {
  setStep: null,
};

BehaviorEdit.propTypes = {
  setStep: PropTypes.func,
  itemId: PropTypes.string.isRequired,
};

export default BehaviorEdit;
