import { useEffect, useState } from 'react';

import MDBox from 'shared/components/MDBox';

import ProgramList from './programs_list';
import ProgramCreate from './programs_create';
import ProgramEdit from './programs_edit';
import ProgramsViewPerformance from './programs_view_performance';

function getStepContent(stepIndex, setProgramStep, programId, setProgramId) {
  switch (stepIndex) {
    case 'list':
      return (
        <ProgramList
          setProgramStep={setProgramStep}
          setProgramId={setProgramId}
        />
      );

    case 'create':
      return <ProgramCreate setProgramStep={setProgramStep} />;

    case 'edit':
      return (
        <ProgramEdit setProgramStep={setProgramStep} programId={programId} />
      );

    case 'view_performance':
      return (
        <ProgramsViewPerformance
          setProgramStep={setProgramStep}
          programId={programId}
        />
      );

    default:
      return null;
  }
}

function Programs() {
  const [programStep, setProgramStep] = useState('list');

  const [programId, setProgramId] = useState();

  useEffect(() => {
    return () => {
      setProgramStep('list');
      setProgramId();
    };
  }, []);

  return (
    <MDBox>
      {getStepContent(programStep, setProgramStep, programId, setProgramId)}
    </MDBox>
  );
}

export default Programs;
