import { ErrorMessage, Field, Form, Formik } from 'formik';
import { initialValuesEvaluations } from '../../schemas/initial_values';
import {
  evaluationsValidations,
  validateCriteria,
} from '../../schemas/validations';
import toastError from 'shared/components/snackbar/error/toastError';
import MDButton from 'shared/components/MDButton';
import MDTypography from 'shared/components/MDTypography';
import MDBox from 'shared/components/MDBox';
import FormField from 'shared/components/form_field';
import { Autocomplete, Card, Checkbox, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import MDProgress from 'shared/components/MDProgress';
import DatatableApi from 'shared/components/tables/DatatableApi';
import MDInput from 'shared/components/MDInput';
import { useParams } from 'react-router-dom';
import criterionService from 'shared/services/criterion/criterion.service';
import evaluationsService from 'shared/services/evaluations/evaluations.service';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import usersService from 'shared/services/users/users.service';
import {
  handleValue,
  renderInput,
  noOptionsTextMinCharacters,
} from 'shared/components/autocomplete/autocompleteConfigs';
import { userLogged } from 'utils/essentialData';

function EvaluationCreate({ setStep }) {
  const { uuid } = useParams();

  const today = new Date().toISOString().split('T')[0];

  // datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(25);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const [newCriterionValue, setNewCriterionValue] = useState('');
  const [supervisorData, setSupervisorData] = useState();

  const loggedUser = userLogged() ?? undefined;
  const permitionsFields = () => [99].includes(loggedUser.role);

  const [selectedItemTable, setSelectedItemTable] = useState([]);

  const [errorCriterion, setErrorCriterion] = useState();

  const handleCheckboxChange = (uuid) => {
    setSelectedItemTable((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((item) => item !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleSubmit = async (values, actions) => {
    try {
      const filteredCriterions = values.criterions.filter(
        (v) => !selectedItemTable.includes(v.uuid)
      );

      const data = {
        ...values,
        criterions: filteredCriterions,
        user_uuid: uuid,
        supervisor_uuid:
          loggedUser.role === 1 ? loggedUser.uuid : values.supervisor_uuid.uuid,
      };
      await evaluationsService.create(data);

      toastSuccess('Avaliação criada com sucesso');
      setStep('graph_list');
    } catch (error) {
      toastError(error.message);
    }
  };

  // datatable
  const dataTableData = {
    columns: [
      {
        Header: 'Bloquear critério',
        accessor: 'check_item',
        Cell: ({ cell: { row } }) => {
          const original = row.original;
          return (
            <Checkbox
              checked={selectedItemTable.includes(original?.uuid)}
              onChange={() => handleCheckboxChange(original.uuid)}
              size='small'
            />
          );
        },
        width: '10%',
        canBeSorted: false,
      },
      {
        Header: 'Critério de avaliação',
        accessor: 'name',
        width: '50%',
      },
      {
        Header: 'nota *',
        accessor: 'note',
        Cell: ({ cell: { row } }) => (
          <>
            <Field name={`criterions[${row.index}].note`}>
              {({ field, form, meta }) => (
                <MDInput
                  {...field}
                  disabled={selectedItemTable.includes(row.original?.uuid)}
                  value={field.value || 0}
                  type='number'
                  fullWidth
                />
              )}
            </Field>

            <MDTypography
              component='div'
              variant='caption'
              color='error'
              fontWeight='regular'
              mt={0.75}
            >
              <ErrorMessage name={`criterions[${row.index}].note`} />
            </MDTypography>
          </>
        ),
      },
      {
        Header: 'Comentário',
        accessor: 'comment',
        Cell: ({ cell: { row } }) => (
          <>
            <Field name={`criterions[${row.index}].comment`}>
              {({ field, form, meta }) => (
                <MDInput
                  {...field}
                  disabled={selectedItemTable.includes(row.original?.uuid)}
                  value={field.value || ''}
                  type='text'
                  fullWidth
                />
              )}
            </Field>
            <MDTypography
              component='div'
              variant='caption'
              color='error'
              fontWeight='regular'
              mt={0.75}
            >
              <ErrorMessage name={`criterions[${row.index}].comment`} />
            </MDTypography>
          </>
        ),
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadDatatable = async () => {
    try {
      const res = await criterionService.list(`?status=1`);
      if (res) {
        setDataTable(res);

        const criterionsRemap = res.data.map((m) => ({
          ...m,
          name: m.name,
          note: 0,
          comment: '',
        }));

        setInitialValuesFromApi({
          ...initialValuesEvaluations,
          date: new Date().toISOString().split('T')[0] || '',
          criterions: criterionsRemap,
        });
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const addCriterion = async (setFieldValue, criterions) => {
    try {
      const data = {
        name: newCriterionValue,
        role: 2,
      };

      const res = await criterionService.create(data);
      const newItem = {
        uuid: res.uuid,
        name: res.name,
        note: 0,
        comment: '',
      };

      setDataTable((prevDataTable) => ({
        ...prevDataTable,
        data: [...prevDataTable.data, newItem],
      }));

      const updatedCriterions = [...criterions, newItem];
      setFieldValue('criterions', updatedCriterions);

      setNewCriterionValue('');
    } catch (error) {
      toastError(error.message);
    }
  };

  const loadSupervisors = async (page = 1, limit = 25, filter = '') => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await usersService.getTherapistSupervisors(
        `?uuid=${uuid}&page=${page}&limit=${limit}&status=1${filterValue}`
      );
      if (res) {
        setSupervisorData(res);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const validateCriteria = (values) => {
    const { criterions } = values;
    const allBlocked = criterions.every((criterion) =>
      selectedItemTable.includes(criterion.uuid)
    );
    if (allBlocked) {
      setErrorCriterion(
        'Você deve deixar pelo menos um critério desbloqueado.'
      );
      return true;
    }
    setErrorCriterion();
    return false;
  };

  useEffect(() => {
    loadDatatable();
  }, []);

  return (
    <Card sx={{ p: 3 }}>
      <Formik
        initialValues={initialValuesFromApi ?? initialValuesEvaluations}
        validationSchema={evaluationsValidations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            date: dateV,
            supervisor_uuid: supervisor_uuidV,
          } = values;

          return (
            <Form id='user-edit-form' autoComplete='off'>
              <MDBox id='basic-info' sx={{ overflow: 'visible' }}>
                <MDBox display='flex' justifyContent='space-between'>
                  <MDTypography variant='h5' pb={3}>
                    Criar avaliação
                  </MDTypography>
                </MDBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <FormField
                      name='name'
                      label='Título da avaliação *'
                      type='text'
                      value={nameV}
                      error={errors.name && touched.name}
                      success={nameV.length > 0 && !errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      fieldShrink
                      name='date'
                      label='Data da avaliação *'
                      type='date'
                      value={dateV}
                      error={errors.date && touched.date}
                      success={dateV.length > 0 && !errors.date}
                      inputProps={{
                        max: today,
                      }}
                    />
                  </Grid>
                </Grid>

                {permitionsFields() && (
                  <MDBox>
                    <Autocomplete
                      options={supervisorData?.data ?? []}
                      noOptionsText={noOptionsTextMinCharacters(supervisorData)}
                      getOptionLabel={(op) => op.name}
                      value={handleValue(supervisor_uuidV)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      onChange={(e, value) => {
                        setFieldValue('supervisor_uuid', value);
                      }}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'supervisor_uuid',
                          'Supervisor *',
                          handleBlur,
                          (event) => {
                            const { value } = event.target;
                            if (value && value.length >= 3) {
                              loadSupervisors(1, 50, value);
                            } else {
                              setSupervisorData();
                            }
                          }
                        )
                      }
                    />
                  </MDBox>
                )}

                <MDBox>
                  <MDTypography variant='h6'>Notas</MDTypography>
                </MDBox>

                <MDBox mb={4}>
                  {loadingData ? (
                    <MDProgress
                      variant='gradient'
                      variantProgress='indeterminate'
                      color='primary'
                    />
                  ) : (
                    <DatatableApi
                      table={dataTableData}
                      onRowSelected={setRowSelected}
                      entriesPerPage={{ defaultValue: tableLimit }}
                      setTableLimit={setTableLimit}
                      tableLimit={tableLimit}
                      setTablePage={setTablePage}
                      tablePage={tablePage}
                      tableTotal={tableTotal}
                      showEntriesPerPage={false}
                      showTotalEntries={false}
                    />
                  )}
                </MDBox>

                {errorCriterion && (
                  <MDTypography
                    component='div'
                    variant='caption'
                    color='error'
                    fontWeight='regular'
                    align='center'
                    mt={0.75}
                  >
                    {errorCriterion}
                  </MDTypography>
                )}

                <MDBox
                  display='flex'
                  flexDirection='column'
                  gap={2}
                  mb={2}
                  p={2}
                  sx={{
                    background: '#F6F6F6',
                    borderRadius: '10px',
                  }}
                >
                  <MDTypography variant='h6'>
                    Adicionar novo critério de avaliação
                  </MDTypography>

                  <MDInput
                    label='Critério'
                    value={newCriterionValue ?? ''}
                    name='criterion'
                    multiline
                    rows={3}
                    variant='standard'
                    onChange={(e) => {
                      setNewCriterionValue(e.target.value);
                    }}
                  />
                  <MDButton
                    disabled={!newCriterionValue}
                    color='primary'
                    type='button'
                    size='small'
                    onClick={() => {
                      addCriterion(setFieldValue, values.criterions);
                    }}
                    sx={{ width: 'fit-content' }}
                  >
                    adicionar novo critério
                  </MDButton>
                </MDBox>

                <MDBox display='flex' width='24rem' ml='auto' gap={3}>
                  <MDButton
                    color='secondary'
                    type='button'
                    size='large'
                    onClick={() => setStep('graph_list')}
                    fullWidth
                    variant='outlined'
                  >
                    cancelar
                  </MDButton>
                  <MDButton
                    disabled={isSubmitting || validateCriteria(values)}
                    color='primary'
                    type='submit'
                    size='large'
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </MDBox>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}

export default EvaluationCreate;
