import MDBox from 'shared/components/MDBox';
import { PropTypes } from 'prop-types';

import GraphProgramPerformance from './graph_program_performance';
import HistorytList from './history_list';
import programsService from 'shared/services/programs/programs.service';
import toastError from 'shared/components/snackbar/error/toastError';
import { useEffect, useState } from 'react';

function ProgramsViewPerformance({ setProgramStep, programId }) {
  const [programData, setProgramData] = useState();

  const loadProgramSession = async () => {
    try {
      const res = await programsService.read(programId);
      if (res) {
        setProgramData(res);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    loadProgramSession();
  }, []);

  return (
    <MDBox display='flex' flexDirection='column' gap={3}>
      {programData && (
        <>
          <GraphProgramPerformance
            setStep={setProgramStep}
            itemData={programData}
          />
          <HistorytList programId={programData.uuid} />
        </>
      )}
    </MDBox>
  );
}

ProgramsViewPerformance.propTypes = {
  setProgramStep: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
};

export default ProgramsViewPerformance;
