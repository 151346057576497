/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Card,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import { useState, useEffect } from 'react';
import MDButton from 'shared/components/MDButton';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/form_field';
import initialValues from '../schemas/initial_values';
import { validationsEdit } from '../schemas/validations';
import {
  setValue,
  renderInput,
  handleValue,
} from 'shared/components/autocomplete/autocompleteConfigs';
import UsersService from 'shared/services/users/users.service';
import {
  parseDateFromApi,
  rolesOptions,
  translateRoleNumberToString,
  ufOptions,
  userLogged,
} from 'utils/essentialData';
import { maskCEP, maskPhone } from 'utils/masks';
import { getCep } from 'shared/services/api_utils/api_utils.service';
import toastError from 'shared/components/snackbar/error/toastError';
import { returnOnlyNumber } from 'utils';
import { maskCPF } from 'utils/masks';
import { formFieldDDIAdornment } from 'shared/components/form_field/form_field_configs';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import { multipleChips } from 'shared/components/autocomplete/autocompleteConfigs';

function UsersPersonalData({ userData }) {
  const theme = useTheme();
  const { uuid } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const [supervisorOptions, setSupervisorOptions] = useState([]);

  const loggedUser = userLogged() ?? undefined;

  const filterOptionsRole = createFilterOptions({
    stringify: translateRoleNumberToString,
  });

  // const filterOptionsStatus = createFilterOptions({
  //   stringify: (option) => translateStatus(option),
  // });

  const handlePermissions = () => {
    if (loggedUser?.role === 99) return true;
    return false;
  };

  const loadSupervisor = async (page = 1, limit = 200, filter = '') => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await UsersService.list(
        `?status=1&role=1&page=${page}&limit=${limit}${filterValue}`
      );
      if (res) setSupervisorOptions(res.data);
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadUser = async () => {
    try {
      const user = userData;

      setInitialValuesFromApi({
        ...user,
        phone: maskPhone(user.phone) || '',
        emergency_contact: user.emergency_contact || '',
        professional_role: user.professional_role || '',
        admissiondate: parseDateFromApi(user.admissiondate) || '',
        rpa_number_registration: user.rpa_number_registration || '',
        cep: maskCEP(user.cep) || '',
        address: user.address || '',
        number: user.number || '',
        complement: user.complement || '',
        neighborhood: user.neighborhood || '',
        city: user.city || '',
        state: user.state || '',
        email: user.email || '',
        birthdate: parseDateFromApi(user.birthdate) || '',
        document: maskCPF(user.document) || '',
        supervisor_uuids: user.supervisors ? user.supervisors : [],
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const cepHandler = async (event, setFieldValue) => {
    try {
      const result = await getCep(event);
      if (result !== null && result !== undefined) {
        setFieldValue('address', result.street ? result.street : '');
        setFieldValue(
          'neighborhood',
          result.neighborhood ? result.neighborhood : ''
        );
        setFieldValue('city', result.city ? result.city : '');
        setFieldValue('state', result.state ? result.state : '');
      } else toastError('CEP inválido');
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { supervisor_uuids } = values;
    try {
      const res = await UsersService.edit({
        ...values,
        supervisor_uuids: supervisor_uuids.map((v) => v.uuid),
        uuid,
      });
      if (
        res.email !== initialValuesFromApi.email &&
        res.hasOwnProperty('token')
      )
        localStorage.setItem('TOKEN', res.token);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        'Usuário editado com sucesso',
        '',
        true,
        handlePermissions() ? '/usuarios' : '/terapeutas',
        'Redirecionar para usuários'
      );
    } catch (e) {
      if (e.message.includes('<p>')) {
        toastError(<div dangerouslySetInnerHTML={{ __html: e.message }} />);
      } else toastError(e.message);
    }
  };

  useEffect(() => {
    loadSupervisor();
  }, []);

  useEffect(() => {
    if (userData) {
      loadUser();
    }
  }, [userData]);

  return (
    <Formik
      initialValues={initialValuesFromApi ?? initialValues}
      validationSchema={validationsEdit[0]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
      }) => {
        const {
          role: roleV,
          name: nameV,
          phone: phoneV,
          email: emailV,
          emergency_contact: emergency_contactV,
          professional_role: professional_roleV,
          admissiondate: admissiondateV,
          rpa_number_registration: rpa_number_registrationV,
          cep: cepV,
          address: addressV,
          number: numberV,
          complement: complementV,
          neighborhood: neighborhoodV,
          city: cityV,
          state: stateV,
          document: documentV,
          birthdate: birthdateV,
          supervisor_uuids: supervisor_uuidsV,
          // status: statusV,
        } = values;

        return (
          <Form id='user-edit-form' autoComplete='off'>
            <Card id='basic-info' sx={{ overflow: 'visible' }}>
              <MDBox p={3}>
                <MDTypography variant='h5'>Editar Usuário</MDTypography>
              </MDBox>

              {handlePermissions() && (
                <>
                  <MDBox px={3}>
                    <Autocomplete
                      options={rolesOptions ?? []}
                      getOptionLabel={translateRoleNumberToString}
                      value={handleValue(roleV)}
                      onChange={(e, value) =>
                        setValue(setFieldValue, 'role', value)
                      }
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateRoleNumberToString(option)}
                        </Box>
                      )}
                      filterOptions={filterOptionsRole}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'role',
                          'Perfil *',
                          handleBlur
                        )
                      }
                    />
                  </MDBox>

                  {roleV === 2 && (
                    <MDBox px={3}>
                      <Autocomplete
                        multiple
                        options={supervisorOptions}
                        freeSolo
                        getOptionLabel={(option) => option?.name}
                        value={supervisor_uuidsV || ''}
                        isOptionEqualToValue={(option, value) =>
                          option.uuid === value.uuid
                        }
                        onChange={(e, value) =>
                          setFieldValue('supervisor_uuids', value)
                        }
                        renderTags={(value, getTagProps) =>
                          multipleChips(value, getTagProps, 'name', theme)
                        }
                        renderInput={(params) =>
                          renderInput(
                            'form',
                            params,
                            'supervisor_uuids',
                            'Nome do supervisor *',
                            handleBlur
                          )
                        }
                      />
                    </MDBox>
                  )}
                </>
              )}

              <MDBox px={3}>
                <FormField
                  name='name'
                  label='Nome completo *'
                  type='text'
                  value={nameV}
                  error={errors.name && touched.name}
                  success={nameV.length > 0 && !errors.name}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='birthdate'
                  fieldShrink
                  label='Data de nascimento *'
                  type='date'
                  value={birthdateV}
                  error={errors.birthdate && touched.birthdate}
                  success={birthdateV.length > 0 && !errors.birthdate}
                />
              </MDBox>

              {handlePermissions() && (
                <MDBox px={3}>
                  <FormField
                    name='document'
                    label='CPF *'
                    type='text'
                    value={documentV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue('document', maskCPF(value));
                    }}
                    error={errors.document && touched.document}
                    success={documentV.length > 0 && !errors.document}
                  />
                </MDBox>
              )}

              <MDBox px={3}>
                <FormField
                  name='phone'
                  label='Telefone *'
                  type='text'
                  value={phoneV}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('phone', maskPhone(value));
                  }}
                  InputProps={{
                    startAdornment: formFieldDDIAdornment(),
                  }}
                  error={errors.phone && touched.phone}
                  success={phoneV.length > 0 && !errors.phone}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='email'
                  label='Email *'
                  type='email'
                  value={emailV}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='emergency_contact'
                  label='Contato Emergencial *'
                  type='text'
                  value={emergency_contactV}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('emergency_contact', value);
                    // setFieldValue("emergency_contact", maskPhone(value));
                  }}
                  // InputProps={{
                  //   startAdornment: formFieldDDIAdornment(),
                  // }}
                  error={errors.emergency_contact && touched.emergency_contact}
                  success={
                    emergency_contactV.length > 0 && !errors.emergency_contact
                  }
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='professional_role'
                  label='Formação Profissional *'
                  type='text'
                  value={professional_roleV}
                  error={errors.professional_role && touched.professional_role}
                  success={
                    professional_roleV.length > 0 && !errors.professional_role
                  }
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  fieldShrink
                  name='admissiondate'
                  label='Data de admissão *'
                  type='date'
                  value={admissiondateV}
                  error={errors.admissiondate && touched.admissiondate}
                  success={admissiondateV.length > 0 && !errors.admissiondate}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='rpa_number_registration'
                  label='Número de Registro para RPA ou MEI'
                  type='text'
                  value={rpa_number_registrationV}
                  error={
                    errors.rpa_number_registration &&
                    touched.rpa_number_registration
                  }
                  success={
                    rpa_number_registrationV.length > 0 &&
                    !errors.rpa_number_registration
                  }
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='cep'
                  label='CEP *'
                  type='text'
                  value={cepV}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('cep', maskCEP(value));
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const valueNumber = returnOnlyNumber(value);
                    if (valueNumber && valueNumber.length > 7)
                      cepHandler(valueNumber, setFieldValue);
                  }}
                  error={errors.cep && touched.cep}
                  success={cepV.length > 0 && !errors.cep}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='address'
                  label='Logradouro *'
                  type='text'
                  value={addressV}
                  error={errors.address && touched.address}
                  success={addressV.length > 0 && !errors.address}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='number'
                  label='Número *'
                  type='number'
                  value={numberV}
                  error={errors.number && touched.number}
                  success={numberV.length > 0 && !errors.number}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='complement'
                  label='Complemento'
                  type='text'
                  value={complementV}
                  error={errors.complement && touched.complement}
                  success={complementV.length > 0 && !errors.complement}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='neighborhood'
                  label='Bairro *'
                  type='text'
                  value={neighborhoodV}
                  error={errors.neighborhood && touched.neighborhood}
                  success={neighborhoodV.length > 0 && !errors.neighborhood}
                />
              </MDBox>

              <MDBox px={3}>
                <FormField
                  name='city'
                  label='Cidade *'
                  type='text'
                  value={cityV}
                  error={errors.city && touched.city}
                  success={cityV.length > 0 && !errors.city}
                />
              </MDBox>

              <MDBox px={3}>
                <Autocomplete
                  options={ufOptions ?? []}
                  getOptionLabel={(option) => option || ''}
                  value={handleValue(stateV)}
                  onChange={(e, value) =>
                    setValue(setFieldValue, 'state', value)
                  }
                  renderInput={(params) =>
                    renderInput('form', params, 'state', 'UF *', handleBlur)
                  }
                />
              </MDBox>

              <MDBox py={3} px={3} width='12rem' ml='auto'>
                <MDButton
                  disabled={isSubmitting}
                  color='primary'
                  type='submit'
                  size='large'
                  fullWidth
                >
                  SALVAR
                </MDButton>
              </MDBox>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default UsersPersonalData;
