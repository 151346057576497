/* eslint-disable react-hooks/exhaustive-deps */
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import UsersService from 'shared/services/users/users.service';
import toastError from 'shared/components/snackbar/error/toastError';

import UsersPersonalData from './users_edit';
import TherapistPerformance from './therapist_view_performance';

function getTabs() {
  return ['Informações do usuário', 'Avaliação de desempenho'];
}

function getStepContent(stepIndex, setUserStep, userData, setUserData) {
  switch (stepIndex) {
    case 0:
      return <UsersPersonalData userData={userData} />;

    case 1:
      return <TherapistPerformance />;

    default:
      return null;
  }
}

function UsersEdit() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { uuid } = useParams();

  const [userStep, setUserStep] = useState(0);
  const [userData, setUserData] = useState();

  const tabs = getTabs();

  const handleSetTabValue = (event, newValue) => setUserStep(newValue);

  const loadUser = async () => {
    try {
      const user = await UsersService.read(uuid);
      if (user) setUserData(user);
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {userData?.role == 2 && (
        <MDBox py={1} px={2} mb={2} bgColor='primary' borderRadius='lg'>
          <Tabs
            orientation={smallScreen ? 'vertical' : 'horizontal'}
            value={userStep}
            onChange={handleSetTabValue}
          >
            {tabs.map((label) => (
              <Tab key={label} label={label} />
            ))}
          </Tabs>
        </MDBox>
      )}
      {getStepContent(userStep, setUserStep, userData, setUserData)}
    </DashboardLayout>
  );
}

export default UsersEdit;
