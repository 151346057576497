import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

// Materia l Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from 'context';
import { AuthProvider } from 'context/AuthContext';
import { DateFilterProvider } from 'context/DateFilterContext';

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <DateFilterProvider>
          <App />
        </DateFilterProvider>
      </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
