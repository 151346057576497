import { isAxiosError } from 'axios';
import { BEHAVIOR_API, BEHAVIORS_API } from './behaviors';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class BehaviorsService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${BEHAVIOR_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar comportamento');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${BEHAVIORS_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar comportamentos');
        }
      }

      throw new Error(error);
    }
  };

  create = async ({
    name,
    application_phase,
    procedure,
    register_type,
    patient_uuid,
    status,
    supervisor_uuid,
  }) => {
    try {
      const response = await customAxios.post(
        `${BEHAVIOR_API}/patient/${patient_uuid}`,
        {
          name,
          application_phase,
          procedure,
          register_type,
          patient_uuid,
          status,
          supervisor_uuid,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar comportamento');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    {
      name,
      application_phase,
      procedure,
      register_type,
      patient_uuid,
      status,
      supervisor_uuid,
    }
  ) => {
    try {
      const response = await customAxios.put(`${BEHAVIOR_API}/${uuid}`, {
        name,
        application_phase,
        procedure,
        register_type,
        patient_uuid,
        status,
        supervisor_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar comportamento');
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(`${BEHAVIOR_API}/${uuid}`, {
        status,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar comportamento';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${BEHAVIOR_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar comportamento');
        }
      }

      throw new Error(error);
    }
  };

  getGraphData = async (uuid, filter) => {
    try {
      const response = await customAxios.get(
        `${BEHAVIOR_API}/graph/${uuid}${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar gráfico');
        }
      }

      throw new Error(error);
    }
  };
}

export default new BehaviorsService();
