import { isAxiosError } from 'axios';
import { EVALUATION_API, EVALUATIONS_API } from './evaluations';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class EvaluationsService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${EVALUATION_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar avaliação');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${EVALUATIONS_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar avaliaçõess');
        }
      }

      throw new Error(error);
    }
  };

  create = async ({ name, date, user_uuid, criterions, supervisor_uuid }) => {
    try {
      const response = await customAxios.post(`${EVALUATION_API}`, {
        name,
        date,
        user_uuid,
        criterions,
        supervisor_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar avaliação');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    { name, date, user_uuid, criterions, supervisor_uuid }
  ) => {
    try {
      const response = await customAxios.put(`${EVALUATION_API}/${uuid}`, {
        name,
        date,
        user_uuid,
        criterions,
        supervisor_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar avaliação');
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${EVALUATION_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar avaliação');
        }
      }

      throw new Error(error);
    }
  };

  getGraphData = async (uuid, filter = '') => {
    try {
      const response = await customAxios.get(
        `${EVALUATION_API}/user/${uuid}/graph${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar avaliação');
        }
      }

      throw new Error(error);
    }
  };
}

export default new EvaluationsService();
