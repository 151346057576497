import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const downloadGraph = (elRef, title, fileName = 'chart') => {
  const chartContainer = elRef.current;

  if (chartContainer) {
    html2canvas(chartContainer).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape');

      // Adicionando um título centralizado antes do gráfico
      pdf.setFontSize(20);
      pdf.setFont('helvetica', 'bold');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const titleWidth = pdf.getTextWidth(title);
      const x = (pageWidth - titleWidth) / 2;
      pdf.text(title, x, 20);

      // Adicionando o gráfico (imagem) abaixo do título com uma margem
      const titleHeightMm = 8; // Altura do título em mm
      pdf.addImage(imgData, 'PNG', 10, 20 + titleHeightMm, 280, 150);

      pdf.save(`${fileName}.pdf`);
    });
  }
};
