import MDBox from 'shared/components/MDBox';
import { PropTypes } from 'prop-types';

import GraphBehaviorPerformance from './graph_behavior_performance';
import RecordedObservations from './recorded_observations';
import { useEffect, useState } from 'react';
import toastError from 'shared/components/snackbar/error/toastError';
import behaviorsService from 'shared/services/behaviors/behaviors.service';

function BehaviorsViewPerformance({ setStep, itemId }) {
  const [behaviorData, setBehaviorData] = useState();

  const loadBehaviorSession = async () => {
    try {
      const res = await behaviorsService.read(itemId);
      if (res) {
        setBehaviorData(res);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    loadBehaviorSession();

    return () => {
      setBehaviorData();
    };
  }, []);

  return (
    <MDBox display='flex' flexDirection='column' gap={3}>
      {behaviorData && (
        <>
          <GraphBehaviorPerformance itemData={behaviorData} setStep={setStep} />
          <RecordedObservations itemData={behaviorData} />
        </>
      )}
    </MDBox>
  );
}

BehaviorsViewPerformance.propTypes = {
  setStep: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default BehaviorsViewPerformance;
