export function generateNullArray(qtd) {
  return Array(qtd).fill(null);
}

export function calculateNullArraySize(...arrays) {
  return arrays.reduce((maxSize, array) => Math.max(maxSize, array.length), 0);
}

export function generateCombinedArray(dataArray, nullArraySize) {
  return [...dataArray, ...generateNullArray(nullArraySize)];
}

export function generateNumberArray(qtd, start = 0) {
  return Array.from({ length: qtd }, (_, i) => i + start);
}

export function findLargestArraySize(...arrays) {
  // Verifica se arrays foi passado e se não está vazio
  if (arrays.length === 0) return 0;

  // Usa o método reduce para encontrar o maior tamanho
  return arrays.reduce((max, array) => Math.max(max, array.length), 0);
}

export function createDataGroupMultiline(group) {
  return {
    corrects: group.corrects || [],
    incorrects: group.incorrects || [],
    corrects_help: group.corrects_help || [],
  };
}

export function createDataGroup(group) {
  return {
    value: group.value || [],
  };
}
