import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import { useTheme } from '@mui/material';
import GraphLegends from '../legends';
import {
  createDataGroup,
  generateNullArray,
  generateNumberArray,
} from '../chartFunctions';
import MDBox from 'shared/components/MDBox';
import customLabelsPlugin from './customLabelsPlugin';
import { createAnnotations } from './functionsSectorizedGraph';
import PropTypes from 'prop-types';

// Registrar os componentes que serão utilizados do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const SectorizedLineGraph = ({
  graphData,
  chartRef,
  chartContainerRef,
  registerType,
}) => {
  const theme = useTheme();
  const { palette } = theme;
  const isDuration = registerType === 'duration';
  const [buttonEnabled, setButtonEnabled] = useState({
    y1: false,
    y2: false,
    y3: false,
  });
  const [dataGraph, setDataGraph] = useState({ labels: [], datasets: [] });
  const { baseline, intervention, maintenance } = graphData;

  const dataGroups = {
    baseline: createDataGroup(baseline),
    intervention: createDataGroup(intervention),
    maintenance: createDataGroup(maintenance),
  };

  const { value: dataBaseLineCorrects } = dataGroups.baseline;
  const { value: dataInterventionWHelpCorrects } = dataGroups.intervention;
  const { value: dataMaintenanceCorrects } = dataGroups.maintenance;

  function convertTimeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToTime(seconds) {
    const h = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const s = String(seconds % 60).padStart(2, '0');
    return `${h}:${m}:${s}`;
  }

  //baseline

  const formatDataBaseLineCorrects = useMemo(() => {
    const transformedData =
      registerType === 'duration'
        ? dataBaseLineCorrects.map(convertTimeToSeconds)
        : dataBaseLineCorrects;

    return [
      ...transformedData,
      ...generateNullArray(
        dataInterventionWHelpCorrects.length + dataMaintenanceCorrects.length
      ),
    ];
  }, [
    registerType,
    dataBaseLineCorrects,
    dataInterventionWHelpCorrects,
    dataMaintenanceCorrects,
  ]);

  // Intervention With Help
  const formatDataInterventionWithHelpCorrects = useMemo(() => {
    const transformedData =
      registerType === 'duration'
        ? dataInterventionWHelpCorrects.map(convertTimeToSeconds)
        : dataInterventionWHelpCorrects;

    return [
      ...generateNullArray(dataBaseLineCorrects.length),
      ...transformedData,
      ...generateNullArray(dataMaintenanceCorrects.length),
    ];
  }, [
    registerType,
    dataBaseLineCorrects,
    dataInterventionWHelpCorrects,
    dataMaintenanceCorrects,
  ]);

  // Maintenance
  const formatDataMaintenanceCorrects = useMemo(() => {
    const transformedData =
      registerType === 'duration'
        ? dataMaintenanceCorrects.map(convertTimeToSeconds)
        : dataMaintenanceCorrects;

    return [
      ...generateNullArray(
        dataBaseLineCorrects.length + dataInterventionWHelpCorrects.length
      ),
      ...transformedData,
    ];
  }, [
    registerType,
    dataBaseLineCorrects,
    dataInterventionWHelpCorrects,
    dataMaintenanceCorrects,
  ]);

  // axis X length
  const labelNumbersArray = generateNumberArray(
    dataBaseLineCorrects.length +
      dataInterventionWHelpCorrects.length +
      dataMaintenanceCorrects.length,
    1
  );

  // posições das barras
  const divisionAPosition = dataBaseLineCorrects.length;
  const divisionBPosition =
    divisionAPosition + dataInterventionWHelpCorrects.length;

  // Dados para o gráfico
  const data = useMemo(() => {
    const createDataset = (data, label, borderColor, yAxisID) => {
      if (data.length === 0) return null;
      return {
        data,
        label,
        borderColor,
        borderWidth: 2,
        fill: false,
        yAxisID,
      };
    };

    const validDatasets = [];

    const hasValidData = (data) => data && data.some((item) => item !== null);

    if (hasValidData(formatDataBaseLineCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataBaseLineCorrects,
          'acertos baseline',
          palette['ternary'].main,
          'y2'
        )
      );
    }

    if (hasValidData(formatDataInterventionWithHelpCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataInterventionWithHelpCorrects,
          'acertos intervenção',
          palette['ternary'].main,
          'y2'
        )
      );
    }

    if (hasValidData(formatDataMaintenanceCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataMaintenanceCorrects,
          'acertos manutenção',
          palette['ternary'].main,
          'y2'
        )
      );
    }

    const filteredDatasets = validDatasets.filter(Boolean);
    return {
      labels: labelNumbersArray,
      datasets: filteredDatasets,
    };
  }, [graphData]);

  const annotationLines = createAnnotations(
    divisionAPosition,
    divisionBPosition
  );

  // Configurações para o gráfico
  const options = useMemo(
    () => ({
      scales: {
        y2: {
          beginAtZero: true,
          // max: registerType,
          ticks: {
            stepSize: isDuration ? 30 : 1,
            callback: (value) =>
              isDuration ? secondsToTime(value) : value.toFixed(0),
          },
          display: true,
          title: {
            display: true,
            text: isDuration ? 'Duração (HH:mm:ss)' : 'Frequência',
          },
        },
        x: {
          min: 1,
          title: {
            display: true,
            text: 'Sessões',
          },
        },
      },
      plugins: {
        annotation: {
          annotations: annotationLines,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const value = context.raw; // Obtém o valor do ponto atual
              return registerType === 'duration'
                ? `Tempo: ${secondsToTime(value)}`
                : `Valor: ${value}`;
            },
            title: (context) => {
              return `Sessão ${context[0].label}`; // Customiza o título
            },
          },
          backgroundColor: '#ffffff', // Cor de fundo do tooltip
          titleColor: '#000000', // Cor do título
          bodyColor: '#000000', // Cor do texto
          borderColor: '#cccccc', // Cor da borda
          borderWidth: 1, // Largura da borda
        },
      },
      layout: {
        padding: {
          top: 40,
        },
      },
    }),
    [graphData]
  );

  const toggleAnswers = (yAxisID) => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    const datasets = chart.data.datasets;

    datasets.forEach((dataset, index) => {
      if (dataset.yAxisID === yAxisID) {
        const isVisible = chart.isDatasetVisible(index);
        if (isVisible) {
          chart.hide(index);
        } else {
          chart.show(index);
        }
      }
    });

    chart.update();
  };

  const verifyIfHasYAxisID = (yAxisID) => {
    if (!chartRef.current) return false;

    const chart = chartRef.current;
    const datasets = chart.data.datasets;

    const hasMatchingYAxis = datasets.some(
      (dataset) =>
        dataset.yAxisID === yAxisID &&
        dataset.data.some((value) => value !== null)
    );

    setButtonEnabled((prev) => ({
      ...prev,
      [yAxisID]: hasMatchingYAxis,
    }));
  };

  useEffect(() => {
    chartRef?.current?.update();

    if (graphData) {
      setDataGraph(data);
    }
  }, [graphData, data, options, chartRef]);

  useEffect(() => {
    if (dataGraph.datasets.length > 0) {
      verifyIfHasYAxisID('y2');
    }
  }, [dataGraph]);

  return (
    <MDBox ref={chartContainerRef}>
      <Line
        ref={chartRef}
        data={dataGraph}
        options={options}
        plugins={[customLabelsPlugin()]}
      />

      <MDBox id='legend' display='flex' justifyContent='center' gap={2}>
        {buttonEnabled.y2 && (
          <GraphLegends
            label={isDuration ? 'Duração' : 'Frequência'}
            color='ternary'
            onClick={() => toggleAnswers('y2')}
          />
        )}
      </MDBox>
    </MDBox>
  );
};

SectorizedLineGraph.propTypes = {
  graphData: PropTypes.any.isRequired,
  chartRef: PropTypes.any.isRequired,
  chartContainerRef: PropTypes.any.isRequired,
  registerType: PropTypes.string.isRequired,
};

export default React.memo(SectorizedLineGraph);
