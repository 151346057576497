import React from 'react';
import * as Yup from 'yup';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Slide,
} from '@mui/material';

import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialogTitle';
import dialogContent from 'assets/theme/components/dialog/dialogContent';
import dialogActions from 'assets/theme/components/dialog/dialogActions';

import MDBox from '../../MDBox';
import MDTypography from '../../MDTypography';
import { Form, Formik } from 'formik';
import FormField from '../../form_field';
import MDButton from '../../MDButton';
import ButtonGroupField from './ButtonGroupField';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

const initialValues = {
  initial_date: '',
  final_date: '',
  type_date: 'all',
};

const validations = [
  Yup.object().shape({
    // initial_date: Yup.string().required('O nome é obrigatório'),
    // final_date: Yup.string().required('A fase de aplicação é obrigatória'),
    // type_date: Yup.string().required('O procedimento é obrigatório'),
  }),
];

function ModalFilterGraph({
  open,
  onClose,
  setFilterValue,
  initialFilterValue,
}) {
  const handleSubmit = (values, actions) => {
    setFilterValue(values);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: 'center' }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={dialogTitle}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' alignItems='center' gap={1}>
            <Icon>warning</Icon>
            <MDTypography
              color='text'
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              Filtrar por data
            </MDTypography>
          </MDBox>

          <IconButton onClick={() => onClose()}>
            <Icon fontSize='small'>clear</Icon>
          </IconButton>
        </MDBox>
      </DialogTitle>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />

      <Formik
        initialValues={initialFilterValue ?? initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            initial_date: initial_dateV,
            final_date: final_dateV,
            type_date: type_dateV,
          } = values;

          const isButtonEnabled =
            (initial_dateV && initial_dateV.trim() !== '') ||
            (final_dateV && final_dateV.trim() !== '') ||
            (type_dateV && type_dateV.trim() !== '');

          return (
            <Form id='user-edit-form' autoComplete='off'>
              <DialogContent sx={{ ...dialogContent, paddingY: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      fieldShrink
                      name='initial_date'
                      label='Data inicial'
                      type='date'
                      value={initial_dateV}
                      error={errors.initial_date && touched.initial_date}
                      success={initial_dateV.length > 0 && !errors.initial_date}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue('initial_date', value);

                        if (value) {
                          setFieldValue('type_date', '');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      fieldShrink
                      name='final_date'
                      label='Data final'
                      type='date'
                      value={final_dateV}
                      error={errors.final_date && touched.final_date}
                      success={final_dateV.length > 0 && !errors.final_date}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue('final_date', value);

                        if (value) {
                          setFieldValue('type_date', '');
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <MDBox display='flex'>
                  <ButtonGroupField
                    form={{ setFieldValue, values }}
                    options={[
                      { value: 'week', label: 'Última Semana' },
                      { value: 'month', label: 'Último Mês' },
                      { value: 'all', label: 'Desde o Início' },
                    ]}
                  />
                </MDBox>
              </DialogContent>
              <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
              <DialogActions
                sx={{ ...dialogActions, justifyContent: 'space-between' }}
              >
                <MDButton color='dark' variant='outlined' onClick={onClose}>
                  cancelar
                </MDButton>
                <MDButton
                  color='primary'
                  type='submit'
                  disabled={!isButtonEnabled || isSubmitting}
                >
                  filtrar
                </MDButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

ModalFilterGraph.defaultProps = {
  onClose: null,
};

ModalFilterGraph.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  setFilterValue: PropTypes.func.isRequired,
  initialFilterValue: PropTypes.shape({
    initial_date: PropTypes.string.isRequired,
    final_date: PropTypes.string.isRequired,
    type_date: PropTypes.oneOf(['all', 'week', 'month']).isRequired,
  }),
};

export default ModalFilterGraph;
