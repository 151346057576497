const initialValues = {
  role: '',
  name: '',
  phone: '',
  email: '',
  emergency_contact: '',
  professional_role: '',
  admissiondate: '',
  rpa_number_registration: '',
  cep: '',
  address: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  birthdate: '',
  document: '',
  status: 1,
  supervisor_uuid: [],
};

export const initialValuesEvaluationsCriterions = {
  name: '',
  note: 0,
  comment: '',
};

export const initialValuesEvaluations = {
  name: '',
  date: '',
  criterions: [],
  supervisor_uuid: '',
};

export default initialValues;
