import { Card, Icon } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BarGraph from 'shared/components/charts/barGraph';
import MDBox from 'shared/components/MDBox';
import MDButton from 'shared/components/MDButton';
import MDProgress from 'shared/components/MDProgress';
import MDTypography from 'shared/components/MDTypography';
import ModalViewEvaluation from 'shared/components/modals/modalViewEvaluation';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import DatatableApi from 'shared/components/tables/DatatableApi';
import NotificationItem from 'shared/examples/Items/NotificationItem';
import evaluationsService from 'shared/services/evaluations/evaluations.service';
import usersService from 'shared/services/users/users.service';
import { formatDateFromApi } from 'utils/essentialData';

function GraphEvaluationList({ setStep, setEvaluation }) {
  const { uuid } = useParams();
  const [graphData, setGraphData] = useState();
  const [userData, setUserData] = useState();

  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  //modal delete
  const [openPopUp, setOpenPopUp] = useState(false);

  // view
  const [openView, setOpenView] = useState(false);

  const calculateAverageGrades = (notes) => {
    const total = notes.reduce((acc, noteObj) => acc + noteObj.note, 0);
    const average = (total / notes.length).toFixed(2);

    return average;
  };

  const loadGraph = async () => {
    try {
      const res = await evaluationsService.getGraphData(
        uuid,
        `?page=1&limit=50`
      );
      if (res) {
        setGraphData({
          labels: res.data.map((item) => formatDateFromApi(item.date)),
          notes: res.data.map((item) =>
            calculateAverageGrades(item.criterions)
          ),
        });
      }
    } catch (error) {
      toastError(error);
    }
  };

  const loadUser = async () => {
    try {
      const user = await usersService.read(uuid);
      if (user) setUserData(user);
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadEvaluations = async (page = 1, limit = 10, filter = '') => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await evaluationsService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res.meta.total);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  function formatDecimalNumberToBrazilianStandard(input) {
    const number = typeof input === 'string' ? parseFloat(input) : input;

    if (number % 1 === 0) {
      return number.toLocaleString('pt-BR');
    } else {
      return number.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }

  // datatable
  const dataTableData = {
    columns: [
      {
        Header: 'Titulo',
        accessor: 'name',
      },
      {
        Header: 'Data de avaliação',
        accessor: 'date',
        Cell: ({ value }) => formatDateFromApi(value),
        // width: "15%",
      },
      {
        Header: 'Supervisor',
        accessor: 'supervisor',
        Cell: ({ value }) => value.name,
      },
      {
        Header: 'Média de notas',
        accessor: 'average_note',
        Cell: ({ value }) =>
          value ? formatDecimalNumberToBrazilianStandard(value) : '',
        // width: "15%",
      },
      {
        Header: 'ações',
        accessor: 'action',
        width: '15%',
      },
    ],

    rows: dataTable?.data || [],
  };

  // modal delete
  const handleDelete = (row) => {
    setOpenPopUp(true);
  };
  const handleCancelDelete = () => setOpenPopUp(false);
  const handleApplyDelete = async () => {
    try {
      await evaluationsService.delete(rowSelected.original.uuid);
      toastSuccess('Programa apagado com sucesso');
      loadEvaluations(tablePage, tableLimit);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  useEffect(() => {
    loadGraph();
    loadEvaluations();
    loadUser();
  }, []);

  return (
    <MDBox display='flex' flexDirection='column' gap={2}>
      <Card>
        <MDBox pt={3} px={3}>
          <MDTypography variant='h5' fontWeight='medium'>
            {userData?.name ?? 'Terapeuta'}
          </MDTypography>
          <MDTypography variant='body2'>Avaliação de desempenho</MDTypography>
        </MDBox>

        <MDBox pt={3} px={3} pb={3}>
          <BarGraph graphData={graphData} />
        </MDBox>
      </Card>

      <Card>
        <MDBox display='flex' justifyContent='space-between' pt={3} px={3}>
          <MDTypography variant='h5' fontWeight='medium'>
            Histórico de avaliações
          </MDTypography>
          <MDButton
            variant='gradient'
            color='primary'
            onClick={() => {
              setStep('evaluation_create');
            }}
          >
            criar
          </MDButton>
        </MDBox>

        {loadingData ? (
          <MDProgress
            variant='gradient'
            variantProgress='indeterminate'
            color='primary'
          />
        ) : (
          <DatatableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          >
            {[
              <NotificationItem
                onClick={() => {
                  setEvaluation(rowSelected.original);
                  setStep('evaluation_edit');
                }}
                icon={<Icon>create</Icon>}
                title='Editar'
                key='option_edit'
              />,
              <NotificationItem
                onClick={() => {
                  setEvaluation(rowSelected.original);
                  setOpenView(true);
                }}
                icon={<Icon>visibility</Icon>}
                title='Visualizar'
                key='option_view'
              />,
              <NotificationItem
                onClick={() => {
                  handleDelete(rowSelected);
                }}
                icon={<Icon>delete</Icon>}
                title='Excluir'
                key='option_delete'
              />,
            ]}
          </DatatableApi>
        )}
      </Card>

      <PopUpDelete
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        title='Exclusão de avaliação'
        contentText='Tem certeza que deseja excluir esta avaliação?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />

      <ModalViewEvaluation
        open={openView}
        onClose={() => setOpenView(false)}
        evaluation={rowSelected}
      />
    </MDBox>
  );
}

export default GraphEvaluationList;
