import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useMemo } from 'react';

const DateFilterContext = createContext();

export const useDateFilter = () => useContext(DateFilterContext);

export const DateFilterProvider = ({ children }) => {
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [typeDate, setTypeDate] = useState('all'); // 'all', 'week', 'month', etc.

  const [firstGraphDate, setFirstGraphDate] = useState('');

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      initialDate,
      setInitialDate,
      finalDate,
      setFinalDate,
      typeDate,
      setTypeDate,
      firstGraphDate,
      setFirstGraphDate,
    }),
    [initialDate, finalDate, typeDate, firstGraphDate]
  );

  return (
    <DateFilterContext.Provider value={contextValue}>
      {children}
    </DateFilterContext.Provider>
  );
};

DateFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
