/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import toastError from 'shared/components/snackbar/error/toastError';
import { Autocomplete, Grid, Icon } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DataTableApi from 'shared/components/tables/DatatableApi';

import MDInput from 'shared/components/MDInput';
import MDButton from 'shared/components/MDButton';
import PopUp, { dialogButton } from 'shared/components/pop_up';
import { useParams } from 'react-router-dom';
import MDProgress from 'shared/components/MDProgress';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import NotificationItem from 'shared/examples/Items/NotificationItem';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import ProgramsService from 'shared/services/programs/programs.service';
import {
  translateAplicationPhase,
  translateProgramStatus,
  userLogged,
} from 'utils/essentialData';

const programsStatusOptions = [
  {
    slug: 'all',
    value: 'Todos os programas',
  },
  {
    slug: 'programs_ongoing',
    value: 'Programas em andamento',
  },
  {
    slug: 'programs_finished',
    value: 'Programas finalizados',
  },
];

function ProgramList({ setProgramStep, setProgramId }) {
  const { uuid } = useParams();

  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [filterStatus, setFilterStatus] = useState(programsStatusOptions[0]);
  const [filterStatusSearch, setFilterStatusSearch] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  //modal delete
  const [openPopUp, setOpenPopUp] = useState(false);
  //modal status
  const [openStatusPopUp, setOpenStatusPopUp] = useState(false);

  const loggedUser = userLogged() ?? undefined;

  const handlePermissions = () => {
    if (loggedUser?.role === 99) return true;
    if (loggedUser?.role === 1) return true;
    return false;
  };

  const dataTableData = {
    columns: [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Fase de aplicação',
        accessor: 'application_phase',
        Cell: ({ value }) =>
          value ? translateAplicationPhase(value.category) : '',
        // width: "15%",
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (value ? translateProgramStatus(value) : ''),
        // width: "15%",
      },
      {
        Header: 'ações',
        accessor: 'action',
        width: '15%',
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadPrograms = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter ? `&${filter}` : '';
      const res = await ProgramsService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}${filterValue}`,
        setLoadingData
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  // modal delete
  const handleDelete = (row) => {
    setOpenPopUp(true);
  };
  const handleCancelDelete = () => setOpenPopUp(false);
  const handleApplyDelete = async () => {
    try {
      await ProgramsService.delete(rowSelected.original.uuid);
      toastSuccess('Programa apagado com sucesso');
      loadPrograms(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  // modal status
  const handleStatus = (row) => {
    setOpenStatusPopUp(true);
  };
  const handleCancelStatus = () => setOpenStatusPopUp(false);
  const handleApplyStatus = async () => {
    try {
      let programStatus = rowSelected.original.status;
      if (programStatus === 'ongoing') programStatus = 'Finalizado';
      else programStatus = 'Em andamento';

      const data = {
        uuid: rowSelected.original.uuid,
        status: programStatus,
      };

      await ProgramsService.updateStatus(data);
      toastSuccess('Status do programa alterado com sucesso');
      loadPrograms(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenStatusPopUp(false);
    }
  };

  useEffect(() => {
    if (filterStatus) {
      let filter = '';

      if (filterStatus.slug === 'programs_ongoing') filter = 'status=ongoing';
      if (filterStatus.slug === 'programs_finished') filter = 'status=finished';
      setFilterSearch(filter);
      loadPrograms(tablePage, tableLimit, filter);
    }
  }, [filterStatus, tablePage, tableLimit]);

  return (
    <>
      <MDBox>
        <MDBox px={3} pt={3} lineHeight={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <MDTypography variant='gradient'>Programas</MDTypography>
            </Grid>
            {handlePermissions() && (
              <Grid item xs={12} sm={2}>
                <MDButton
                  variant='gradient'
                  color='primary'
                  size='small'
                  fullWidth
                  onClick={() => setProgramStep('create')}
                >
                  Criar
                </MDButton>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Autocomplete
                defaultValue='all'
                options={programsStatusOptions ?? []}
                value={filterStatus ?? null}
                getOptionLabel={(opt) => (opt?.value ? opt.value : '')}
                isOptionEqualToValue={(op, va) => op.slug === va.slug}
                onChange={(_, value) => {
                  setFilterStatus(value);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant='outlined' />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
        {loadingData ? (
          <MDProgress
            variant='gradient'
            variantProgress='indeterminate'
            color='primary'
          />
        ) : (
          <DataTableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          >
            {[
              <NotificationItem
                onClick={() => {
                  const { uuid } = rowSelected.original;
                  setProgramId(uuid);
                  setProgramStep('edit');
                  //   navigate(`/pacientes/editar/${uuid}/editar`);
                }}
                icon={<Icon>create</Icon>}
                title='Editar'
                key='option_edit'
              />,
              <NotificationItem
                onClick={() => {
                  handleStatus(rowSelected);
                }}
                icon={<Icon>compare_arrows</Icon>}
                title='Alterar status'
                key='option_status'
              />,
              <NotificationItem
                onClick={() => {
                  const { uuid } = rowSelected.original;
                  setProgramId(uuid);
                  setProgramStep('view_performance');
                  //   navigate(`/pacientes/editar/${uuid}/ver`);
                }}
                icon={<Icon>visibility</Icon>}
                title='Visualizar Desempenho'
                key='option_view'
              />,
              <NotificationItem
                onClick={() => {
                  handleDelete(rowSelected);
                }}
                icon={<Icon>delete</Icon>}
                title='Excluir'
                key='option_delete'
              />,
            ]}
          </DataTableApi>
        )}
      </MDBox>

      <PopUpDelete
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        title='Exclusão de programa'
        contentText='Tem certeza que deseja excluir este programa?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />

      <PopUp
        open={openStatusPopUp}
        onClose={() => setOpenStatusPopUp(false)}
        title='Alterar status'
        contentText={
          <MDBox>
            Deseja alterar o status deste programa para
            <strong> "Finalizado"</strong>?
          </MDBox>
        }
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelStatus}
            style={dialogButton}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton
            color='primary'
            onClick={handleApplyStatus}
            style={dialogButton}
            key='confirm'
          >
            Confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

ProgramList.defaultProps = {
  setProgramStep: () => {},
};

ProgramList.propTypes = {
  setProgramStep: PropTypes.func,
  setProgramId: PropTypes.func.isRequired,
};

export default ProgramList;
