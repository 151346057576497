import { ErrorMessage, Field, Form, Formik } from 'formik';
import { initialValuesEvaluations } from '../../schemas/initial_values';
import { evaluationsValidations } from '../../schemas/validations';
import toastError from 'shared/components/snackbar/error/toastError';
import MDButton from 'shared/components/MDButton';
import MDTypography from 'shared/components/MDTypography';
import MDBox from 'shared/components/MDBox';
import FormField from 'shared/components/form_field';
import { Autocomplete, Card, Checkbox, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import MDProgress from 'shared/components/MDProgress';
import DatatableApi from 'shared/components/tables/DatatableApi';
import MDInput from 'shared/components/MDInput';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import { useParams } from 'react-router-dom';
import evaluationsService from 'shared/services/evaluations/evaluations.service';
import criterionService from 'shared/services/criterion/criterion.service';
import { userLogged, parseDateFromApi } from 'utils/essentialData';
import usersService from 'shared/services/users/users.service';
import {
  noOptionsTextMinCharacters,
  handleValue,
  renderInput,
} from 'shared/components/autocomplete/autocompleteConfigs';

function EvaluationEdit({ setStep, evaluation }) {
  const { uuid } = useParams();
  const today = new Date().toISOString().split('T')[0];

  // datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const [newCriterionValue, setNewCriterionValue] = useState('');
  const [supervisorData, setSupervisorData] = useState();

  const loggedUser = userLogged() ?? undefined;
  const permitionsFields = () => [99].includes(loggedUser.role);

  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const [selectedItemTable, setSelectedItemTable] = useState([]);

  const [errorCriterion, setErrorCriterion] = useState();

  const handleCheckboxChange = (uuid) => {
    setSelectedItemTable((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((item) => item !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleSubmit = async (values, actions) => {
    try {
      const filteredCriterions = values.criterions.filter(
        (v) => !selectedItemTable.includes(v.uuid)
      );

      const data = {
        ...values,
        criterions: filteredCriterions,
        user_uuid: uuid,
        supervisor_uuid: values.supervisor_uuid.uuid,
      };

      await evaluationsService.edit(evaluation.uuid, data);

      toastSuccess('Avaliação editada com sucesso');
      setStep('graph_list');
    } catch (error) {
      toastError(error.message);
    }
  };

  // datatable
  const dataTableData = {
    columns: [
      {
        Header: 'Bloquear critério',
        accessor: 'check_item',
        Cell: ({ cell: { row } }) => {
          const original = row.original;
          return (
            <Checkbox
              checked={selectedItemTable.includes(original?.uuid)}
              onChange={() => handleCheckboxChange(original.uuid)}
              size='small'
            />
          );
        },
        width: '10%',
        canBeSorted: false,
      },
      {
        Header: 'Critério de avaliação',
        accessor: 'name',
        width: '50%',
      },
      {
        Header: 'nota *',
        accessor: 'note',
        Cell: ({ cell: { row } }) => (
          <>
            <Field name={`criterions[${row.index}].note`}>
              {({ field, form, meta }) => (
                <MDInput
                  {...field}
                  disabled={selectedItemTable.includes(row.original?.uuid)}
                  value={field.value || 0}
                  type='number'
                  fullWidth
                />
              )}
            </Field>

            <MDTypography
              component='div'
              variant='caption'
              color='error'
              fontWeight='regular'
              mt={0.75}
            >
              <ErrorMessage name={`criterions[${row.index}].note`} />
            </MDTypography>
          </>
        ),
      },
      {
        Header: 'Comentário',
        accessor: 'comment',
        Cell: ({ cell: { row } }) => (
          <>
            <Field name={`criterions[${row.index}].comment`}>
              {({ field, form, meta }) => (
                <MDInput
                  {...field}
                  disabled={selectedItemTable.includes(row.original?.uuid)}
                  value={field.value || ''}
                  type='text'
                  fullWidth
                />
              )}
            </Field>
            <MDTypography
              component='div'
              variant='caption'
              color='error'
              fontWeight='regular'
              mt={0.75}
            >
              <ErrorMessage name={`criterions[${row.index}].comment`} />
            </MDTypography>
          </>
        ),
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadDataFromApi = async () => {
    try {
      const res = await evaluationsService.read(evaluation.uuid);
      const criterionsApi = await criterionService.list(`?status=1`);

      if (res) {
        const existingCriterions = res.criterions || [];
        const extraCriterions = criterionsApi.data.filter(
          (criterion) =>
            !existingCriterions.some((c) => c.criterion_uuid === criterion.uuid)
        );

        const criterionsRemap = [
          ...existingCriterions.map((criterion) => ({
            ...criterion,
            uuid: criterion.criterion_uuid,
            name: criterion.criterion_name ?? '',
            note: criterion.note ?? '',
            comment: criterion.comment ?? '',
          })),
          ...extraCriterions.map((criterion) => ({
            ...criterion,
            note: 0,
            comment: '',
          })),
        ];

        setSelectedItemTable(extraCriterions.map((v) => v.uuid));
        setDataTable({
          data: criterionsRemap,
          meta: criterionsApi.meta,
        });

        setInitialValuesFromApi({
          ...res,
          supervisor_uuid: res.supervisor ?? null,
          date: res.date ? parseDateFromApi(res.date) : '',
          criterions: criterionsRemap,
        });
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const addCriterion = async (setFieldValue, criterions) => {
    try {
      const data = {
        name: newCriterionValue,
        role: 2,
      };

      const res = await criterionService.create(data);
      const newItem = {
        uuid: res.uuid,
        name: res.name,
        note: 0,
        comment: '',
      };

      setDataTable((prevDataTable) => ({
        ...prevDataTable,
        data: [...prevDataTable.data, newItem],
      }));

      const updatedCriterions = [...criterions, newItem];
      setFieldValue('criterions', updatedCriterions);

      setNewCriterionValue('');
    } catch (error) {
      toastError(error.message);
    }
  };

  //modal delete
  const handleDelete = () => setOpenDeletePopup(true);

  const handleCancelDelete = () => setOpenDeletePopup(false);

  const handleApplyDelete = async () => {
    try {
      await evaluationsService.delete(evaluation.uuid);
      toastSuccess('Avaliação apagada com sucesso');
      setStep('graph_list');
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenDeletePopup(false);
    }
  };

  const loadSupervisors = async (page = 1, limit = 25, filter = '') => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await usersService.list(
        `?page=${page}&limit=${limit}&role=1${filterValue}`
      );
      if (res) {
        setSupervisorData(res);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const validateCriteria = (values) => {
    const { criterions } = values;
    const allBlocked = criterions.every((criterion) =>
      selectedItemTable.includes(criterion.uuid)
    );
    if (allBlocked) {
      setErrorCriterion(
        'Você deve deixar pelo menos um critério desbloqueado.'
      );
      return true;
    }
    setErrorCriterion();
    return false;
  };

  useEffect(() => {
    if (evaluation) loadDataFromApi();
  }, [evaluation]);

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Formik
          initialValues={initialValuesFromApi ?? initialValuesEvaluations}
          validationSchema={evaluationsValidations[0]}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => {
            const {
              name: nameV,
              date: dateV,
              supervisor_uuid: supervisor_uuidV,
            } = values;

            return (
              <Form id='user-edit-form' autoComplete='off'>
                <MDBox id='basic-info' sx={{ overflow: 'visible' }}>
                  <MDBox display='flex' justifyContent='space-between'>
                    <MDTypography variant='h5' pb={3}>
                      Editar avaliação
                    </MDTypography>
                  </MDBox>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <FormField
                        name='name'
                        label='Título da avaliação *'
                        type='text'
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV.length > 0 && !errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        fieldShrink
                        name='date'
                        label='Data da avaliação *'
                        type='date'
                        value={dateV}
                        error={errors.date && touched.date}
                        success={dateV.length > 0 && !errors.date}
                        inputProps={{
                          max: today,
                        }}
                      />
                    </Grid>
                  </Grid>

                  {permitionsFields() && (
                    <MDBox>
                      <Autocomplete
                        options={supervisorData?.data ?? []}
                        noOptionsText={noOptionsTextMinCharacters(
                          supervisorData
                        )}
                        getOptionLabel={(op) => op.name}
                        value={handleValue(supervisor_uuidV)}
                        isOptionEqualToValue={(option, value) =>
                          option.uuid === value.uuid
                        }
                        onChange={(e, value) => {
                          setFieldValue('supervisor_uuid', value);
                        }}
                        renderInput={(params) =>
                          renderInput(
                            'form',
                            params,
                            'supervisor_uuid',
                            'Supervisor *',
                            handleBlur,
                            (event) => {
                              const { value } = event.target;
                              if (value && value.length >= 3) {
                                loadSupervisors(1, 50, value);
                              } else {
                                setSupervisorData();
                              }
                            }
                          )
                        }
                      />
                    </MDBox>
                  )}

                  <MDBox>
                    <MDTypography variant='h6'>Notas</MDTypography>
                  </MDBox>

                  <MDBox mb={4}>
                    {loadingData ? (
                      <MDProgress
                        variant='gradient'
                        variantProgress='indeterminate'
                        color='primary'
                      />
                    ) : (
                      <DatatableApi
                        table={dataTableData}
                        onRowSelected={setRowSelected}
                        entriesPerPage={{ defaultValue: tableLimit }}
                        setTableLimit={setTableLimit}
                        tableLimit={tableLimit}
                        setTablePage={setTablePage}
                        tablePage={tablePage}
                        tableTotal={tableTotal}
                        showEntriesPerPage={false}
                        showTotalEntries={false}
                      />
                    )}
                  </MDBox>

                  {errorCriterion && (
                    <MDTypography
                      component='div'
                      variant='caption'
                      color='error'
                      fontWeight='regular'
                      align='center'
                      mt={0.75}
                    >
                      {errorCriterion}
                    </MDTypography>
                  )}

                  <MDBox
                    display='flex'
                    flexDirection='column'
                    gap={2}
                    mb={2}
                    p={2}
                    sx={{
                      background: '#F6F6F6',
                      borderRadius: '10px',
                    }}
                  >
                    <MDTypography variant='h6'>
                      Adicionar novo critério de avaliação
                    </MDTypography>

                    <MDInput
                      label='Critério'
                      value={newCriterionValue ?? ''}
                      name='criterion'
                      multiline
                      rows={3}
                      variant='standard'
                      onChange={(e) => {
                        setNewCriterionValue(e.target.value);
                      }}
                    />
                    <MDButton
                      disabled={!newCriterionValue}
                      color='primary'
                      type='button'
                      size='small'
                      onClick={() => {
                        addCriterion(setFieldValue, values.criterions);
                      }}
                      sx={{ width: 'fit-content' }}
                    >
                      adicionar novo critério
                    </MDButton>
                  </MDBox>

                  <MDBox display='flex' alignItems='space-between' gap={3}>
                    <MDButton
                      color='error'
                      type='button'
                      size='large'
                      onClick={handleDelete}
                      variant='outlined'
                    >
                      excluir
                    </MDButton>

                    <MDBox display='flex' width='24rem' ml='auto' gap={3}>
                      <MDButton
                        color='secondary'
                        type='button'
                        size='large'
                        onClick={() => setStep('graph_list')}
                        fullWidth
                        variant='outlined'
                      >
                        cancelar
                      </MDButton>
                      <MDButton
                        disabled={isSubmitting || validateCriteria(values)}
                        color='primary'
                        type='submit'
                        size='large'
                        fullWidth
                      >
                        SALVAR
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            );
          }}
        </Formik>
      </Card>
      <PopUpDelete
        open={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title='Exclusão de avaliação'
        contentText='Tem certeza que deseja excluir esta avaliação?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />
    </>
  );
}

export default EvaluationEdit;
