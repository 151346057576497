import { useState } from 'react';

import MDBox from 'shared/components/MDBox';
import BehaviorsList from './behaviors_list';
import BehaviorCreate from './behaviors_create';
import BehaviorEdit from './behaviors_edit';
import BehaviorsViewPerformance from './behaviors_view_performance';

function getStepContent(stepIndex, setStep, itemId, setItemId) {
  switch (stepIndex) {
    case 'list':
      return <BehaviorsList setStep={setStep} setItemId={setItemId} />;

    case 'create':
      return <BehaviorCreate setStep={setStep} />;

    case 'edit':
      return <BehaviorEdit setStep={setStep} itemId={itemId} />;

    case 'view_performance':
      return <BehaviorsViewPerformance setStep={setStep} itemId={itemId} />;

    default:
      return null;
  }
}

function Behaviors() {
  const [behaviorStep, setBehaviorStep] = useState('list');

  const [behaviorId, setBehaviorId] = useState();

  return (
    <MDBox>
      {getStepContent(behaviorStep, setBehaviorStep, behaviorId, setBehaviorId)}
    </MDBox>
  );
}

export default Behaviors;
