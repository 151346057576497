import { Box, Chip, TextField } from '@mui/material';
import FormFieldAutocomplete from 'shared/components/form_field/form_field_autocomplete';

export const noOptionsText = 'Sem opções';

export const noOptionsTextMinCharacters = (value, min = 3) => {
  return value
    ? 'Sem opções'
    : `Digite no mínimo ${min} caracteres para carregar as opções`;
};

export const handleValue = (value) => (value === '' ? null : value);

export const compareOptionToValue = (option, value, compareValue = 'uuid') =>
  option[compareValue] === value[compareValue];

export const setValue = (setFieldValue, fieldName, value) => {
  setFieldValue(fieldName, value !== null ? value : '');
};

export const renderOption = (
  props,
  option,
  _,
  keyV = 'uuid',
  optionValue = 'name'
) => (
  <Box {...props} component='li' key={option[keyV]}>
    {option[optionValue]}
  </Box>
);

export const renderInput = (
  type,
  params,
  name,
  label,
  handleBlur,
  onChange
) => {
  switch (type) {
    case 'form':
      return (
        <FormFieldAutocomplete
          fieldVariant='standard'
          {...params}
          type='text'
          label={label}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
        />
      );

    default:
      return <TextField {...params} label={label} onChange={onChange} />;
  }
};

export const multipleChips = (
  value,
  getTagProps,
  labelOption = null,
  theme,
  translateFunc = null
) => {
  if (translateFunc)
    return value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        style={{ backgroundColor: theme.palette.info.main }}
        label={translateFunc(labelOption ? option[labelOption] : option)}
      />
    ));
  else
    return value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        style={{ backgroundColor: theme.palette.info.main }}
        label={labelOption ? option[labelOption] : option}
      />
    ));
};
