import { isAxiosError } from 'axios';
import { BEHAVIOR_ABC_API, BEHAVIORS_ABC_API } from './behaviors_abc';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class BehaviorsABCService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${BEHAVIOR_ABC_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar comportamento abc');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${BEHAVIORS_ABC_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar comportamentos abc');
        }
      }

      throw new Error(error);
    }
  };

  create = async ({
    antecedent,
    behavior,
    consequence,
    therapist_uuid,
    patient_uuid,
    session_uuid,
  }) => {
    try {
      const response = await customAxios.post(`${BEHAVIOR_ABC_API}`, {
        antecedent,
        behavior,
        consequence,
        therapist_uuid,
        patient_uuid,
        session_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar comportamento abc');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    {
      antecedent,
      behavior,
      consequence,
      therapist_uuid,
      patient_uuid,
      session_uuid,
    }
  ) => {
    try {
      const response = await customAxios.put(`${BEHAVIOR_ABC_API}/${uuid}`, {
        antecedent,
        behavior,
        consequence,
        therapist_uuid,
        patient_uuid,
        session_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar comportamento abc');
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${BEHAVIOR_ABC_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar comportamento abc');
        }
      }

      throw new Error(error);
    }
  };
}

export default new BehaviorsABCService();
