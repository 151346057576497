import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Slide,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialogTitle';
import dialogContent from 'assets/theme/components/dialog/dialogContent';
import dialogActions from 'assets/theme/components/dialog/dialogActions';
import MDTypography from 'shared/components/MDTypography';
import MDButton from 'shared/components/MDButton';
import MDBox from 'shared/components/MDBox';

import StyleTabsInline from 'shared/components/tabs/styleTabsInline';
import ServiceTab from './serviceTab';
import ProgramTab from './programTab';
import BehaviorTab from './behaviorTab';
import PropTypes from 'prop-types';
import sessionsService from 'shared/services/sessions/sessions.service';
import toastError from 'shared/components/snackbar/error/toastError';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

function getTabs() {
  return ['Atendimento', 'Programas', 'Comportamentos'];
}

function getStepContent(stepIndex, setStep, sessionData) {
  switch (stepIndex) {
    case 0:
      return <ServiceTab itemData={sessionData} />;

    case 1:
      return <ProgramTab itemData={sessionData} />;

    case 2:
      return <BehaviorTab itemData={sessionData} />;

    default:
      return null;
  }
}

function ModalRegisterDetails({ open, onClose, sessionId }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const tabs = getTabs();
  const [tabStep, setTabStep] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabStep(newValue);

  const [sessionData, setSessionData] = useState();

  const loadSession = async () => {
    try {
      const res = await sessionsService.read(sessionId);
      if (res) {
        setSessionData(res);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    loadSession();
  }, [sessionId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={dialog}
      PaperProps={{
        sx: {
          minHeight: '90%',
        },
      }}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle sx={dialogTitle}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' alignItems='center' gap={1}>
            <Icon>visibility</Icon>
            <MDTypography
              color='text'
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              Detalhes sobre o registro
            </MDTypography>
          </MDBox>

          <IconButton onClick={() => onClose()}>
            <Icon fontSize='small'>clear</Icon>
          </IconButton>
        </MDBox>
      </DialogTitle>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />

      <DialogContent sx={{ ...dialogContent, paddingY: 3 }}>
        <Tabs
          orientation={smallScreen ? 'vertical' : 'horizontal'}
          value={tabStep}
          onChange={handleSetTabValue}
          sx={StyleTabsInline.tabsInline}
        >
          {tabs.map((label) => (
            <Tab key={label} label={label} sx={StyleTabsInline.tabInline} />
          ))}
        </Tabs>

        {sessionData && (
          <MDBox>{getStepContent(tabStep, setTabStep, sessionData)}</MDBox>
        )}
      </DialogContent>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
      <DialogActions sx={{ ...dialogActions, justifyContent: 'space-between' }}>
        <MDButton color='dark' variant='outlined' onClick={onClose}>
          cancelar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

ModalRegisterDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default ModalRegisterDetails;
